import {Button, Col, Container, Row} from "react-bootstrap";
import AppNavbar from "../AppNavbar";
import {Link} from "react-router-dom";

export default function Success() {
    return (
        <Container>
            <Row>
                <AppNavbar/>
            </Row>
            <Row>
                <Col lg={6}></Col>
                <Col lg={6} className={"mt-3 bg-faded rounded py-2"}>
                    <div>
                        <h1>Thank you</h1>
                        <p>We will be in touch soon</p>
                        <Button className={"mt-4"} as={Link} to={"/"}>Home</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}