import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import product from "../../product.png";
import AppNavbar from "../AppNavbar";

export default class Home extends React.Component {
    render() {
        return (
            <Container>
                <Row className={"mt-4"}>
                    <AppNavbar/>
                </Row>
                <Row className={"mt-4"}>
                    <Col xl={6}></Col>
                    <Col xl={6}>
                        <p className={"main-text text-end float-end fade-bg p-2"}>
                            NO MESS. NO FUSS. <br/> SIMPLE TO USE.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}></Col>
                    <Col xl={6}>
                        <img src={product} className={"float-end fade-in"} alt={"Product"}/>
                    </Col>
                </Row>
            </Container>
        );
    }
}