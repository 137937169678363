import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Instructions from "./pages/Instructions";
import Testimonials from "./pages/Testimonials";
import Contact from "./pages/Contact";
import Success from "./pages/Success";

export default class AppRouter extends React.Component {
    render() {
        return (
            <Router>
                <Routes>
                    <Route path={"/"} element={<Home/>}></Route>
                    <Route path={"/instructions"} element={<Instructions/>}/>
                    <Route path={"/testimonials"} element={<Testimonials/>}/>
                    <Route path={"/contact"} element={<Contact/>}/>
                    <Route path={"/success"} element={<Success/>}/>
                </Routes>
            </Router>
        );
    }
}