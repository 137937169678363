import React from "react";
import AppNavbar from "../AppNavbar";
import {Card, Col, Container, Row} from "react-bootstrap";
import instruction1 from "../../HOW-TO-USE_1.jpg";
import instruction2 from "../../HOW-TO-USE_2.jpg";
import instruction3 from "../../HOW-TO-USE_3.jpg";
import instruction4 from "../../HOW-TO-USE_4.jpg";

export default class Instructions extends React.Component {
    render() {
        return (
            <Container>
                <Row className={"mt-4"}>
                    <AppNavbar/>
                </Row>
                <Row className={"mt-4"}>
                    <Col lg={6}></Col>
                    <Col lg={6}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col sm={4} lg={5} xl={4}>
                                        <img src={instruction1} alt={"Instructions"}/>
                                    </Col>
                                    <Col sm={8} lg={7} xl={8}>
                                        <b>Step 1</b>: Hold the pourer upright and punch the pourer through the tin lid
                                        by hand. This product is not designed for plastic containers, only metal/tin
                                        containers, i.e. Paint Tins, Motor Oil Tins and even Aeroplane Oil Tins.
                                    </Col>
                                </Row>
                                <Row className={"mt-2"}>
                                    <Col sm={4}>
                                        <img src={instruction2} alt={"Instructions"}/>
                                    </Col>
                                    <Col sm={8}>
                                        <b>Step 2</b>: Press the pourer down firmly until the retaining clips click into
                                        place underneath the tin's lid.
                                    </Col>
                                </Row>
                                <Row className={"mt-2"}>
                                    <Col sm={4}>
                                        <img src={instruction3} alt={"Instructions"}/>
                                    </Col>
                                    <Col sm={8}>
                                        <b>Step 3</b>: If the contents of your tin needs stirring, attach the stirrer to
                                        your drill, and bend the stirrer outward for 10 seconds. The stirrer will retain
                                        this bend for effective stirring.
                                    </Col>
                                </Row>
                                <Row className={"mt-2"}>
                                    <Col sm={4}>
                                        <img src={instruction4} alt={"Instructions"}/>
                                    </Col>
                                    <Col sm={8}>
                                        <b>Step 4</b>: Step 4: Place the stirrer through the pourer into the tin, and
                                        turn the drill on until the liquid is mixed into the desired consistency. Remove
                                        the stirrer.
                                        <br/><br/>
                                        You can now pour the contents of the tin through the pourer. Once you're done,
                                        reseal the pourer with the attached cap.
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}