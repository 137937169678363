import React from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import AppNavbar from "../AppNavbar";

export default class Testimonials extends React.Component {
    render() {
        return (
            <Container>
                <Row className={"mt-4"}>
                    <AppNavbar/>
                </Row>
                <Row className={"mt-4"}>
                    <Col lg={6}></Col>
                    <Col lg={6}>
                        <Card>
                            <Card.Body>
                                "After being asked to use this product as a test sample by my business, I have been
                                utterly amazed at how simple and well designed this product is. I find the worst part of
                                painting is the amount of mess and frustration I go through each time I want to open a
                                tin to refill my painting tray! With this product there was no mess, no fiddling with
                                screwdrivers to open the tin and no worrying about closing the lid for an airtight
                                seal!"
                            </Card.Body>
                            <Card.Footer>
                                Rob - Rob's Home Maintenance (Dorset UK)
                            </Card.Footer>
                        </Card>
                        <Card className={"mt-4"}>
                            <Card.Body>
                                "Being in the medical profession I have to take special care and protect my hands from
                                any sort of scratch or cut. I have found that "Punch & Pour" was marvellous when I was
                                recently painting my kitchen. It greatly reduced the risk of possible injury from
                                screwdrivers or hammers. It is a well thought out design providing a excellent way to
                                keep painting safe and fun for all. Well done!"
                            </Card.Body>
                            <Card.Footer>
                                Dr. M (UK)
                            </Card.Footer>
                        </Card>
                        <Card className={"mt-4"}>
                            <Card.Body>
                                "I was really impressed with this well-designed, easy-to-use product. My oil cans stay
                                perfectly sealed after use ready to use for another day"
                            </Card.Body>
                            <Card.Footer>
                                Mr. Brady (UK)
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}