import React from "react";
import {Nav, Navbar} from "react-bootstrap";
import logo from "../logo.png";
import {Link} from "react-router-dom";

export default class AppNavbar extends React.Component {
    render() {
        return (
            <div>
                <Navbar bg={"faded"} expand={"xl"} className={"px-2 rounded-2"}>
                    <Navbar.Brand>
                        <img src={logo} alt={"logo"} />
                    </Navbar.Brand>
                    <Navbar.Toggle/>
                    <Navbar.Collapse>
                        <Nav className={"ms-auto"}>
                            <Nav.Link as={Link} to={"/"}>HOME</Nav.Link>
                            <Nav.Link as={Link} to={"/instructions"}>INSTRUCTIONS</Nav.Link>
                            <Nav.Link as={Link} to={"/testimonials"}>TESTIMONIALS</Nav.Link>
                            <Nav.Link as={Link} to={"/contact"}>CONTACT US</Nav.Link>
                            <Nav.Link className={"buy"}
                                      href={"https://www.amazon.co.uk/Punch-Pour-Spout-pouring-PP2525/dp/B00V3K699W/ref=sr_1_1?ie=UTF8&qid=1449056730&sr=8-1&keywords=punch+and+pour"}>BUY
                                NOW</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}