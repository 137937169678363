import React from "react";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import AppNavbar from "../AppNavbar";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {Turnstile} from "@marsidev/react-turnstile";

export default class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validated: false,
            pending: false,
            error: false,
            form: {
                address_2: ""
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCFSuccess = this.handleCFSuccess.bind(this);
    }

    handleChange(event) {
        this.setState({
            form: {
                ...this.state.form,
                [event.target.name]: event.target.value
            }
        });
    }

    handleCFSuccess(token) {
        this.setState({
            form: {
                ...this.state.form,
                cf_token: token
            }
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const form = event.target;
        if (form.checkValidity()) {
            console.log("form is valid");

            this.setState({
                pending: true
            });

            try {
                const response = await fetch("https://contact.romanolucchesi.com/email", {
                    method: "POST",
                    body: JSON.stringify(this.state.form),
                    headers: new Headers({"content-type": "application/json"})
                });

                if (!response.ok) {
                    this.setState({
                        error: true
                    });
                } else {
                    window.location.href = "/success";
                }
            } catch (e) {
                this.setState({
                    error: true
                });
            }

            this.setState({
                pending: false
            })
        }

        this.setState({
            validated: true
        });
    }

   render() {
        return (
            <Container>
                <Row className={"mt-4"}>
                    <AppNavbar/>
                </Row>
                <Row className={"mt-4"}>
                    <Col lg={6}></Col>
                    <Col lg={6} className={"bg-faded rounded pt-2"}>
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit} onChange={this.handleChange}>
                            <Form.Group className={"mb-3"}>
                                <Form.Label className={"me-2"}>Name</Form.Label>
                                <Form.Control required type={"text"} name={"name"}></Form.Control>
                                <Form.Control.Feedback type={"invalid"}>Please enter a name</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className={"mb-3"}>
                                <Form.Label className={"me-2"}>Address Line 1</Form.Label>
                                <Form.Control required type={"text"} name={"address_1"}></Form.Control>
                                <Form.Control.Feedback type={"invalid"}>Please enter an address</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className={"mb-3"}>
                                <Form.Label className={"me-2"}>Address Line 2</Form.Label>
                                <Form.Control type={"text"} name={"address_2"}></Form.Control>
                            </Form.Group>
                            <Form.Group className={"mb-3"}>
                                <Form.Label className={"me-2"}>Town/City</Form.Label>
                                <Form.Control required type={"text"} name={"city"}></Form.Control>
                                <Form.Control.Feedback type={"invalid"}>Please enter a town or city</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className={"mb-3"}>
                                <Form.Label className={"me-2"}>County</Form.Label>
                                <Form.Control required type={"text"} name={"county"}></Form.Control>
                                <Form.Control.Feedback type={"invalid"}>Please enter a county</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className={"mb-3"}>
                                <Form.Label className={"me-2"}>Post code</Form.Label>
                                <Form.Control required type={"text"} name={"postal_code"}></Form.Control>
                                <Form.Control.Feedback type={"invalid"}>Please enter a postcode</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className={"mb-3"}>
                                <Form.Label className={"me-2"}>Mobile</Form.Label>
                                <Form.Control required type={"mobile"} name={"mobile"}></Form.Control>
                                <Form.Control.Feedback type={"invalid"}>Please enter a mobile number</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className={"mb-3"}>
                                <Form.Label className={"me-2"}>Email Address</Form.Label>
                                <Form.Control required type={"email"} name={"from"}></Form.Control>
                                <Form.Control.Feedback type={"invalid"}>Please enter an email address</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className={"mb-3"}>
                                <Form.Label className={"me-2"}>Query</Form.Label>
                                <Form.Control required as={"textarea"} name={"query"}></Form.Control>
                                <Form.Control.Feedback type={"invalid"}>Please enter a query</Form.Control.Feedback>
                            </Form.Group>

                            <Turnstile siteKey={"0x4AAAAAAARYOYsXw0UYROwl"}
                                       onSuccess={this.handleCFSuccess}
                                       options={{theme: 'light'}}
                                       className={"w-100"}/>

                            <Button type={"submit"} className={"my-2"} disabled={this.state.pending}>
                                {this.state.pending ? "Submitting..." : "Submit"}
                            </Button>

                            {
                                this.state.error ?
                                    <p className={"text-danger"}>Something went wrong... Please try again later</p>
                                    : <></>}
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}