import React from "react";

import './App.css';
import AppRouter from "./components/AppRouter";

export default class App extends React.Component {
    render() {
        return (
            <div>
                <AppRouter/>
            </div>
        );
    }
}
